document.addEventListener("DOMContentLoaded", () => {
    // let deferredPrompt;
    // let installButton = document.querySelector(
    //     ".footer-icon-menu-item.install-button"
    // );

    // if ("serviceWorker" in navigator) {
    //     navigator.serviceWorker.register("/service-worker.js");
    // }

    // window.addEventListener("beforeinstallprompt", (e) => {
    //     e.preventDefault();
    //     deferredPrompt = e;
    //     installButton.className = installButton.className
    //         .replace("hidden", "")
    //         .trim();
    // });

    // installButton.addEventListener("click", () => {
    //     deferredPrompt.prompt();
    //     deferredPrompt.userChoice.then(() => {
    //         deferredPrompt = null;
    //     });
    // });

    const navigationButton: HTMLElement =
        document.querySelector(".navigation-button");
    const navigationCloseButton: HTMLElement = document.querySelector(
        ".navigation-close-button"
    );
    const navigationPanel: HTMLElement =
        document.querySelector(".header-navigation");
    const pageOverlay: HTMLElement = document.querySelector(".page-overlay");

    navigationButton.addEventListener("click", () => {
        navigationPanel.className = navigationPanel.className
            .replace("hidden", "")
            .trim();
        pageOverlay.className = pageOverlay.className
            .replace("hidden", "")
            .trim();
    });
    navigationCloseButton.addEventListener("click", () => {
        if (navigationPanel.className.indexOf("hidden") === -1) {
            navigationPanel.className += " hidden";
            pageOverlay.className += " hidden";
        }
    });

    const header: HTMLElement = document.querySelector("header");
    const scrollTopWrapper: HTMLElement = document.querySelector(
        ".scroll-to-top-wrapper"
    );
    if (header.className.indexOf("fixed") !== -1) {
        const headerPlaceholder: HTMLElement = document.querySelector(
            ".header-placeholder"
        );
        window.addEventListener("resize", (evt) => {
            headerPlaceholder.style.height =
                window.getComputedStyle(header).height;
        });
    } else {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                scrollTopWrapper.className = scrollTopWrapper.className
                    .replace("hidden", "")
                    .trim();
            } else if (scrollTopWrapper.className.indexOf("hidden") === -1) {
                scrollTopWrapper.className += " hidden";
            }
        });
    }
});
